/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateItemDto } from '../models/input-create-item-dto';
import { InputCreateMerchantDataRequestDto } from '../models/input-create-merchant-data-request-dto';
import { InputDeleteItemDto } from '../models/input-delete-item-dto';
import { InputGetItemsMerchantDto } from '../models/input-get-items-merchant-dto';
import { InputUpdateItemDto } from '../models/input-update-item-dto';
import { OutputGetAllBrandsDto } from '../models/output-get-all-brands-dto';
import { OutputGetAllCategoryDto } from '../models/output-get-all-category-dto';
import { OutputGetAllColorDropDownDto } from '../models/output-get-all-color-drop-down-dto';
import { OutputGetAllColorDto } from '../models/output-get-all-color-dto';
import { OutputGetAllDataRequestsDto } from '../models/output-get-all-data-requests-dto';
import { OutputGetAllFeatureWithContent } from '../models/output-get-all-feature-with-content';
import { OutputGetAllItemForDropDownDto } from '../models/output-get-all-item-for-drop-down-dto';
import { OutputGetAllItemForTableDtoListOutputPagedResponseDto } from '../models/output-get-all-item-for-table-dto-list-output-paged-response-dto';
import { OutputGetAllMaterialDto } from '../models/output-get-all-material-dto';
import { OutputGetAllSeasonDto } from '../models/output-get-all-season-dto';
import { OutputGetAllSizeCategoryDto } from '../models/output-get-all-size-category-dto';
import { OutputGetAllSizeDto } from '../models/output-get-all-size-dto';
import { OutputGetAllSubCategoryDto } from '../models/output-get-all-sub-category-dto';
import { OutputGetAllTagsDto } from '../models/output-get-all-tags-dto';

@Injectable({
  providedIn: 'root',
})
export class MerchantItemService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantItemCreateItemMerchant
   */
  static readonly MerchantItemCreateItemMerchantPath = '/api/Merchant/MerchantItem/CreateItemMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemCreateItemMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateItemMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemCreateItemMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemCreateItemMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateItemMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<boolean> {

    return this.merchantItemCreateItemMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemCreateItemMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateItemMerchant$Response(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemCreateItemMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemCreateItemMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateItemMerchant(params?: {
    context?: HttpContext
    body?: InputCreateItemDto
  }
): Observable<boolean> {

    return this.merchantItemCreateItemMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantItemGetAllBrand
   */
  static readonly MerchantItemGetAllBrandPath = '/api/Merchant/MerchantItem/GetAllBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllBrand$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBrand$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllBrandPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllBrand$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBrand$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.merchantItemGetAllBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBrand$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllBrandPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBrand(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.merchantItemGetAllBrand$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllMerchantBrand
   */
  static readonly MerchantItemGetAllMerchantBrandPath = '/api/Merchant/MerchantItem/GetAllMerchantBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllMerchantBrand$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMerchantBrand$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllMerchantBrandPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllMerchantBrand$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMerchantBrand$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.merchantItemGetAllMerchantBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllMerchantBrand()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMerchantBrand$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllBrandsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllMerchantBrandPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllBrandsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllMerchantBrand$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMerchantBrand(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllBrandsDto>> {

    return this.merchantItemGetAllMerchantBrand$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllBrandsDto>>) => r.body as Array<OutputGetAllBrandsDto>)
    );
  }

  /**
   * Path part for operation merchantItemUpdateMerchantBrand
   */
  static readonly MerchantItemUpdateMerchantBrandPath = '/api/Merchant/MerchantItem/UpdateMerchantBrand';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemUpdateMerchantBrand$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateMerchantBrand$Plain$Response(params?: {
    context?: HttpContext
    body?: Array<number>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemUpdateMerchantBrandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemUpdateMerchantBrand$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateMerchantBrand$Plain(params?: {
    context?: HttpContext
    body?: Array<number>
  }
): Observable<boolean> {

    return this.merchantItemUpdateMerchantBrand$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemUpdateMerchantBrand()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateMerchantBrand$Response(params?: {
    context?: HttpContext
    body?: Array<number>
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemUpdateMerchantBrandPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemUpdateMerchantBrand$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateMerchantBrand(params?: {
    context?: HttpContext
    body?: Array<number>
  }
): Observable<boolean> {

    return this.merchantItemUpdateMerchantBrand$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantItemGetAllColor
   */
  static readonly MerchantItemGetAllColorPath = '/api/Merchant/MerchantItem/GetAllColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllColor$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllColor$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllColor$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllColor$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDropDownDto>> {

    return this.merchantItemGetAllColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>) => r.body as Array<OutputGetAllColorDropDownDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllColor()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllColor$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllColor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllColor(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDropDownDto>> {

    return this.merchantItemGetAllColor$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>) => r.body as Array<OutputGetAllColorDropDownDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllBulkColor
   */
  static readonly MerchantItemGetAllBulkColorPath = '/api/Merchant/MerchantItem/GetAllBulkColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllBulkColor$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBulkColor$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllBulkColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllBulkColor$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBulkColor$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDropDownDto>> {

    return this.merchantItemGetAllBulkColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>) => r.body as Array<OutputGetAllColorDropDownDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllBulkColor()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBulkColor$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllBulkColorPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllBulkColor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllBulkColor(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDropDownDto>> {

    return this.merchantItemGetAllBulkColor$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDropDownDto>>) => r.body as Array<OutputGetAllColorDropDownDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetColor
   */
  static readonly MerchantItemGetColorPath = '/api/Merchant/MerchantItem/GetColor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetColor$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetColor$Plain$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetColorPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetColor$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetColor$Plain(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDto>> {

    return this.merchantItemGetColor$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>) => r.body as Array<OutputGetAllColorDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetColor()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetColor$Response(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllColorDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetColorPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllColorDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetColor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetColor(params?: {
    Id?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllColorDto>> {

    return this.merchantItemGetColor$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllColorDto>>) => r.body as Array<OutputGetAllColorDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllSize
   */
  static readonly MerchantItemGetAllSizePath = '/api/Merchant/MerchantItem/GetAllSize';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSize$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSize$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSizePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSize$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSize$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeDto>> {

    return this.merchantItemGetAllSize$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>) => r.body as Array<OutputGetAllSizeDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSize()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSize$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSizePath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSize$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSize(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeDto>> {

    return this.merchantItemGetAllSize$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeDto>>) => r.body as Array<OutputGetAllSizeDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllSizeCategory
   */
  static readonly MerchantItemGetAllSizeCategoryPath = '/api/Merchant/MerchantItem/GetAllSizeCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSizeCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSizeCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSizeCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSizeCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSizeCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeCategoryDto>> {

    return this.merchantItemGetAllSizeCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>) => r.body as Array<OutputGetAllSizeCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSizeCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSizeCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSizeCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSizeCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSizeCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSizeCategoryDto>> {

    return this.merchantItemGetAllSizeCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSizeCategoryDto>>) => r.body as Array<OutputGetAllSizeCategoryDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllCategory
   */
  static readonly MerchantItemGetAllCategoryPath = '/api/Merchant/MerchantItem/GetAllCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.merchantItemGetAllCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllCategoryDto>> {

    return this.merchantItemGetAllCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllCategoryDto>>) => r.body as Array<OutputGetAllCategoryDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllSubCategory
   */
  static readonly MerchantItemGetAllSubCategoryPath = '/api/Merchant/MerchantItem/GetAllSubCategory';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSubCategory$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategory$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSubCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSubCategory$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategory$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.merchantItemGetAllSubCategory$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSubCategory()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategory$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSubCategoryPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSubCategory$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategory(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.merchantItemGetAllSubCategory$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllSubCategoryByCategoryId
   */
  static readonly MerchantItemGetAllSubCategoryByCategoryIdPath = '/api/Merchant/MerchantItem/GetAllSubCategoryByCategoryId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSubCategoryByCategoryId$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategoryByCategoryId$Plain$Response(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSubCategoryByCategoryIdPath, 'get');
    if (params) {
      rb.query('CategoryId', params.CategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSubCategoryByCategoryId$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategoryByCategoryId$Plain(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.merchantItemGetAllSubCategoryByCategoryId$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSubCategoryByCategoryId()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategoryByCategoryId$Response(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSubCategoryByCategoryIdPath, 'get');
    if (params) {
      rb.query('CategoryId', params.CategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSubCategoryByCategoryId$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSubCategoryByCategoryId(params?: {
    CategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSubCategoryDto>> {

    return this.merchantItemGetAllSubCategoryByCategoryId$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSubCategoryDto>>) => r.body as Array<OutputGetAllSubCategoryDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllMaterial
   */
  static readonly MerchantItemGetAllMaterialPath = '/api/Merchant/MerchantItem/GetAllMaterial';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllMaterial$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMaterial$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMaterialDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllMaterialPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMaterialDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllMaterial$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMaterial$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMaterialDto>> {

    return this.merchantItemGetAllMaterial$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMaterialDto>>) => r.body as Array<OutputGetAllMaterialDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllMaterial()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMaterial$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllMaterialDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllMaterialPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllMaterialDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllMaterial$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllMaterial(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllMaterialDto>> {

    return this.merchantItemGetAllMaterial$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllMaterialDto>>) => r.body as Array<OutputGetAllMaterialDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllSeason
   */
  static readonly MerchantItemGetAllSeasonPath = '/api/Merchant/MerchantItem/GetAllSeason';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSeason$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSeason$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSeasonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSeasonPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSeasonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSeason$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSeason$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSeasonDto>> {

    return this.merchantItemGetAllSeason$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSeasonDto>>) => r.body as Array<OutputGetAllSeasonDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllSeason()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSeason$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllSeasonDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllSeasonPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllSeasonDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllSeason$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllSeason(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllSeasonDto>> {

    return this.merchantItemGetAllSeason$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllSeasonDto>>) => r.body as Array<OutputGetAllSeasonDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllItemsForDropDown
   */
  static readonly MerchantItemGetAllItemsForDropDownPath = '/api/Merchant/MerchantItem/GetAllItemsForDropDown';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllItemsForDropDown$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllItemsForDropDown$Plain$Response(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllItemsForDropDownPath, 'get');
    if (params) {
      rb.query('subCategoryId', params.subCategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllItemsForDropDown$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllItemsForDropDown$Plain(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemForDropDownDto>> {

    return this.merchantItemGetAllItemsForDropDown$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>) => r.body as Array<OutputGetAllItemForDropDownDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllItemsForDropDown()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllItemsForDropDown$Response(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllItemsForDropDownPath, 'get');
    if (params) {
      rb.query('subCategoryId', params.subCategoryId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllItemsForDropDown$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllItemsForDropDown(params?: {
    subCategoryId?: number;
    context?: HttpContext
  }
): Observable<Array<OutputGetAllItemForDropDownDto>> {

    return this.merchantItemGetAllItemsForDropDown$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllItemForDropDownDto>>) => r.body as Array<OutputGetAllItemForDropDownDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllItemsForMerchant
   */
  static readonly MerchantItemGetAllItemsForMerchantPath = '/api/Merchant/MerchantItem/GetAllItemsForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllItemsForMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemGetAllItemsForMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputGetItemsMerchantDto
  }
): Observable<StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllItemsForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllItemsForMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemGetAllItemsForMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputGetItemsMerchantDto
  }
): Observable<OutputGetAllItemForTableDtoListOutputPagedResponseDto> {

    return this.merchantItemGetAllItemsForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemForTableDtoListOutputPagedResponseDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllItemsForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemGetAllItemsForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputGetItemsMerchantDto
  }
): Observable<StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllItemsForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllItemsForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemGetAllItemsForMerchant(params?: {
    context?: HttpContext
    body?: InputGetItemsMerchantDto
  }
): Observable<OutputGetAllItemForTableDtoListOutputPagedResponseDto> {

    return this.merchantItemGetAllItemsForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetAllItemForTableDtoListOutputPagedResponseDto>) => r.body as OutputGetAllItemForTableDtoListOutputPagedResponseDto)
    );
  }

  /**
   * Path part for operation merchantItemUpdateItem
   */
  static readonly MerchantItemUpdateItemPath = '/api/Merchant/MerchantItem/UpdateItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemUpdateItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateItem$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemUpdateItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemUpdateItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateItem$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<boolean> {

    return this.merchantItemUpdateItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemUpdateItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateItem$Response(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemUpdateItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemUpdateItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemUpdateItem(params?: {
    context?: HttpContext
    body?: InputUpdateItemDto
  }
): Observable<boolean> {

    return this.merchantItemUpdateItem$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantItemDeleteItem
   */
  static readonly MerchantItemDeleteItemPath = '/api/Merchant/MerchantItem/DeleteItem';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemDeleteItem$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemDeleteItem$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemDeleteItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemDeleteItem$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemDeleteItem$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<boolean> {

    return this.merchantItemDeleteItem$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemDeleteItem()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemDeleteItem$Response(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemDeleteItemPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemDeleteItem$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemDeleteItem(params?: {
    context?: HttpContext
    body?: InputDeleteItemDto
  }
): Observable<boolean> {

    return this.merchantItemDeleteItem$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantItemGetAllTags
   */
  static readonly MerchantItemGetAllTagsPath = '/api/Merchant/MerchantItem/GetAllTags';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllTags$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllTags$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllTagsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllTagsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllTagsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllTags$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllTags$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllTagsDto>> {

    return this.merchantItemGetAllTags$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTagsDto>>) => r.body as Array<OutputGetAllTagsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllTags()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllTags$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllTagsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllTagsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllTagsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllTags$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllTags(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllTagsDto>> {

    return this.merchantItemGetAllTags$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllTagsDto>>) => r.body as Array<OutputGetAllTagsDto>)
    );
  }

  /**
   * Path part for operation merchantItemCreateMerchantDataRequest
   */
  static readonly MerchantItemCreateMerchantDataRequestPath = '/api/Merchant/MerchantItem/CreateMerchantDataRequest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemCreateMerchantDataRequest$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateMerchantDataRequest$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDataRequestDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemCreateMerchantDataRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemCreateMerchantDataRequest$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateMerchantDataRequest$Plain(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDataRequestDto
  }
): Observable<boolean> {

    return this.merchantItemCreateMerchantDataRequest$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemCreateMerchantDataRequest()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateMerchantDataRequest$Response(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDataRequestDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemCreateMerchantDataRequestPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemCreateMerchantDataRequest$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantItemCreateMerchantDataRequest(params?: {
    context?: HttpContext
    body?: InputCreateMerchantDataRequestDto
  }
): Observable<boolean> {

    return this.merchantItemCreateMerchantDataRequest$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantItemGetAllDataRequestsForMerchant
   */
  static readonly MerchantItemGetAllDataRequestsForMerchantPath = '/api/Merchant/MerchantItem/GetAllDataRequestsForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllDataRequestsForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllDataRequestsForMerchant$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllDataRequestsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllDataRequestsForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllDataRequestsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllDataRequestsForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllDataRequestsForMerchant$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllDataRequestsDto>> {

    return this.merchantItemGetAllDataRequestsForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllDataRequestsDto>>) => r.body as Array<OutputGetAllDataRequestsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllDataRequestsForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllDataRequestsForMerchant$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllDataRequestsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllDataRequestsForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllDataRequestsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllDataRequestsForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllDataRequestsForMerchant(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllDataRequestsDto>> {

    return this.merchantItemGetAllDataRequestsForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllDataRequestsDto>>) => r.body as Array<OutputGetAllDataRequestsDto>)
    );
  }

  /**
   * Path part for operation merchantItemGetAllFeatureWithContent
   */
  static readonly MerchantItemGetAllFeatureWithContentPath = '/api/Merchant/MerchantItem/GetAllFeatureWithContent';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllFeatureWithContent$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllFeatureWithContent$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllFeatureWithContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllFeatureWithContent$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllFeatureWithContent$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFeatureWithContent>> {

    return this.merchantItemGetAllFeatureWithContent$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>) => r.body as Array<OutputGetAllFeatureWithContent>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantItemGetAllFeatureWithContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllFeatureWithContent$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantItemService.MerchantItemGetAllFeatureWithContentPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantItemGetAllFeatureWithContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantItemGetAllFeatureWithContent(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllFeatureWithContent>> {

    return this.merchantItemGetAllFeatureWithContent$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllFeatureWithContent>>) => r.body as Array<OutputGetAllFeatureWithContent>)
    );
  }

}
