import { LoaderService } from './shared/loader/LoaderService';
import { TranslateService } from '@ngx-translate/core';
import { Component } from '@angular/core';
import { Router, RouterEvent, RouteConfigLoadStart, RouteConfigLoadEnd, Event } from '@angular/router';
import { ApiConfiguration } from './shared/api';
import { environment } from 'src/environments/environment';

import Editor from './../assets/ckeditor/build/ckeditor';
import { CKEditor5 } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'admin';
  public editor = Editor.Editor;

  editorData = '';

  public defaultConfig: CKEditor5.Config = {
    toolbar: {
      shouldNotGroupWhenFull: true,
    },
    simpleUpload: {
      // The URL that the images are uploaded to.
      uploadUrl: environment.ApiBaseUrl + '/api/common/File/AddFileEditor',
      withCredentials: false,

      // Headers sent along with the XMLHttpRequest to the upload server.
      headers: {},
    },
  };
  constructor(
    translate: TranslateService,
    private router: Router,
    private loaderService: LoaderService,
    private apiConfiguration: ApiConfiguration
  ) {
    apiConfiguration.rootUrl = environment.ApiBaseUrl;
    var asyncLoadCount = 0;
    // router.events.subscribe((event: Event): void => {
    //   if (event instanceof RouteConfigLoadStart) {
    //     asyncLoadCount++;
    //   } else if (event instanceof RouteConfigLoadEnd) {
    //     asyncLoadCount--;
    //   }

    //   if (!!asyncLoadCount) {
    //     loaderService.show();
    //   } else {
    //     loaderService.hide();
    //   }
    //   setTimeout(() => {
    //     loaderService.hide();
    //   }, 2000);
    // });
    const currentLan = localStorage.getItem('lan');
    if (currentLan == null) {
      translate.addLangs(['en', 'en']);
      translate.setDefaultLang('en');
      translate.use('en');
      localStorage.setItem('lan', 'en');
    } else {
      translate.setDefaultLang(currentLan);
      translate.use(currentLan);
    }
    if (translate.currentLang === 'ar') {
      document.body.className = 'rtl';
    }
  }
}
