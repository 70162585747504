/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputForgetPasswordDto } from '../models/input-forget-password-dto';
import { InputRefreshTokenDto } from '../models/input-refresh-token-dto';
import { InputRegisterMerchantDto } from '../models/input-register-merchant-dto';
import { InputResetPasswordDto } from '../models/input-reset-password-dto';
import { InputSignInMerchantDto } from '../models/input-sign-in-merchant-dto';
import { InputUserUpdatePassword } from '../models/input-user-update-password';
import { InputVerficationPasswordDto } from '../models/input-verfication-password-dto';
import { OutputForgetPasswordDto } from '../models/output-forget-password-dto';
import { OutputMerchantRefreshTokenDto } from '../models/output-merchant-refresh-token-dto';
import { OutputSignInMerchantDto } from '../models/output-sign-in-merchant-dto';
import { OutputVerificationPasswordDto } from '../models/output-verification-password-dto';

@Injectable({
  providedIn: 'root',
})
export class MerchantAccountService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantAccountRegisterMerchant
   */
  static readonly MerchantAccountRegisterMerchantPath = '/api/Merchant/MerchantAccount/RegisterMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountRegisterMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountRegisterMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputRegisterMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountRegisterMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountRegisterMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountRegisterMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputRegisterMerchantDto
  }
): Observable<boolean> {

    return this.merchantAccountRegisterMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountRegisterMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountRegisterMerchant$Response(params?: {
    context?: HttpContext
    body?: InputRegisterMerchantDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountRegisterMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountRegisterMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountRegisterMerchant(params?: {
    context?: HttpContext
    body?: InputRegisterMerchantDto
  }
): Observable<boolean> {

    return this.merchantAccountRegisterMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantAccountSignInMerchant
   */
  static readonly MerchantAccountSignInMerchantPath = '/api/Merchant/MerchantAccount/SignInMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountSignInMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountSignInMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputSignInMerchantDto
  }
): Observable<StrictHttpResponse<OutputSignInMerchantDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountSignInMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInMerchantDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountSignInMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountSignInMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputSignInMerchantDto
  }
): Observable<OutputSignInMerchantDto> {

    return this.merchantAccountSignInMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInMerchantDto>) => r.body as OutputSignInMerchantDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountSignInMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountSignInMerchant$Response(params?: {
    context?: HttpContext
    body?: InputSignInMerchantDto
  }
): Observable<StrictHttpResponse<OutputSignInMerchantDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountSignInMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputSignInMerchantDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountSignInMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountSignInMerchant(params?: {
    context?: HttpContext
    body?: InputSignInMerchantDto
  }
): Observable<OutputSignInMerchantDto> {

    return this.merchantAccountSignInMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<OutputSignInMerchantDto>) => r.body as OutputSignInMerchantDto)
    );
  }

  /**
   * Path part for operation merchantAccountUpdatePassword
   */
  static readonly MerchantAccountUpdatePasswordPath = '/api/Merchant/MerchantAccount/UpdatePassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountUpdatePassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountUpdatePassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountUpdatePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountUpdatePassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountUpdatePassword$Plain(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<boolean> {

    return this.merchantAccountUpdatePassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountUpdatePassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountUpdatePassword$Response(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountUpdatePasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountUpdatePassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountUpdatePassword(params?: {
    context?: HttpContext
    body?: InputUserUpdatePassword
  }
): Observable<boolean> {

    return this.merchantAccountUpdatePassword$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantAccountToken
   */
  static readonly MerchantAccountTokenPath = '/api/Merchant/MerchantAccount/Token';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountToken$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountToken$Plain$Response(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputMerchantRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputMerchantRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountToken$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountToken$Plain(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<OutputMerchantRefreshTokenDto> {

    return this.merchantAccountToken$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputMerchantRefreshTokenDto>) => r.body as OutputMerchantRefreshTokenDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountToken()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountToken$Response(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<StrictHttpResponse<OutputMerchantRefreshTokenDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountTokenPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputMerchantRefreshTokenDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountToken$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountToken(params?: {
    context?: HttpContext
    body?: InputRefreshTokenDto
  }
): Observable<OutputMerchantRefreshTokenDto> {

    return this.merchantAccountToken$Response(params).pipe(
      map((r: StrictHttpResponse<OutputMerchantRefreshTokenDto>) => r.body as OutputMerchantRefreshTokenDto)
    );
  }

  /**
   * Path part for operation merchantAccountMerchantForgetPassword
   */
  static readonly MerchantAccountMerchantForgetPasswordPath = '/api/Merchant/MerchantAccount/MerchantForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountMerchantForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountMerchantForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountMerchantForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.merchantAccountMerchantForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountMerchantForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<StrictHttpResponse<OutputForgetPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountMerchantForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputForgetPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountMerchantForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantForgetPassword(params?: {
    context?: HttpContext
    body?: InputForgetPasswordDto
  }
): Observable<OutputForgetPasswordDto> {

    return this.merchantAccountMerchantForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputForgetPasswordDto>) => r.body as OutputForgetPasswordDto)
    );
  }

  /**
   * Path part for operation merchantAccountMerchantVerificationPinForgetPassword
   */
  static readonly MerchantAccountMerchantVerificationPinForgetPasswordPath = '/api/Merchant/MerchantAccount/MerchantVerificationPinForgetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountMerchantVerificationPinForgetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantVerificationPinForgetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountMerchantVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountMerchantVerificationPinForgetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantVerificationPinForgetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.merchantAccountMerchantVerificationPinForgetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountMerchantVerificationPinForgetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantVerificationPinForgetPassword$Response(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<StrictHttpResponse<OutputVerificationPasswordDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountMerchantVerificationPinForgetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputVerificationPasswordDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountMerchantVerificationPinForgetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantVerificationPinForgetPassword(params?: {
    context?: HttpContext
    body?: InputVerficationPasswordDto
  }
): Observable<OutputVerificationPasswordDto> {

    return this.merchantAccountMerchantVerificationPinForgetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<OutputVerificationPasswordDto>) => r.body as OutputVerificationPasswordDto)
    );
  }

  /**
   * Path part for operation merchantAccountMerchantResetPassword
   */
  static readonly MerchantAccountMerchantResetPasswordPath = '/api/Merchant/MerchantAccount/MerchantResetPassword';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountMerchantResetPassword$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantResetPassword$Plain$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountMerchantResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountMerchantResetPassword$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantResetPassword$Plain(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.merchantAccountMerchantResetPassword$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantAccountMerchantResetPassword()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantResetPassword$Response(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantAccountService.MerchantAccountMerchantResetPasswordPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantAccountMerchantResetPassword$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantAccountMerchantResetPassword(params?: {
    context?: HttpContext
    body?: InputResetPasswordDto
  }
): Observable<boolean> {

    return this.merchantAccountMerchantResetPassword$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
