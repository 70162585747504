<div class="digital-add">
  <div class="form-group m-auto">
    <span id="error-validation" *ngIf="control.touched && errors && errors[0]"></span>

    <label *ngIf="!removeLabel && text != ''" class="form-label"
      >{{ text }}

      <span *ngIf="isRequired">*</span>
    </label>

    <input
      *ngIf="!IsDateTime"
      maxlength="90"
      [disabled]="IsDisabled || isView"
      [(ngModel)]="value"
      #input
      class="form-control"
      id="cont{{ text }}"
      [type]="hide && haveHide ? 'password' : type"
      (blur)="onChange($event, input.value)"
      (keyup)="onChange($event, input.value)"
      placeholder="{{ !isView ? text : '' }}"
      [ngClass]="{ isView: isView }"
    />

    <i class="search fa fa-search" [ngClass]="{ iconLabel: removeLabel }"></i>

    <div *ngIf="hent" style="text-align: initial">
      <p>
        <span class="hint"> {{ hent }} </span>
      </p>
    </div>
    <div *ngIf="control.touched && errors && errors[0]" class="text text-danger">
      {{ errors[0] | translate }}
    </div>
  </div>
</div>
