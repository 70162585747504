import { mergeMap as _observableMergeMap, catchError as _observableCatch } from 'rxjs/operators';
import { Observable, throwError as _observableThrow, of as _observableOf } from 'rxjs';
import { Injectable, Inject, Optional, InjectionToken } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { environment } from 'src/environments/environment';

export interface FileParameter {
  data: any;
  fileName: string;
}
@Injectable({ providedIn: 'root' })
export class UploadFile {
  baseUrl;
  protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;

  constructor(private http: HttpClient) {
    this.baseUrl = environment.ApiBaseUrl;
  }
  addFile(myFile: FileParameter): Observable<string> {
    let url_ = this.baseUrl + '/api/common/File/AddFile';
    url_ = url_.replace(/[?&]$/, '');

    const content_ = new FormData();
    if (myFile === null || myFile === undefined) throw new Error("The parameter 'myFile' cannot be null.");
    else content_.append('myFile', myFile.data, myFile.fileName ? myFile.fileName : 'myFile');

    let options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processAddFile(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processAddFile(<any>response_);
            } catch (e) {
              return <Observable<string>>(<any>_observableThrow(e));
            }
          } else return <Observable<string>>(<any>_observableThrow(response_));
        })
      );
  }

  addItemFile(myFile: FileParameter): Observable<string> {
    let url_ = this.baseUrl + '/api/common/File/AddItemFile';
    url_ = url_.replace(/[?&]$/, '');

    const content_ = new FormData();
    if (myFile === null || myFile === undefined) throw new Error("The parameter 'myFile' cannot be null.");
    else content_.append('myFile', myFile.data, myFile.fileName ? myFile.fileName : 'myFile');

    let options_: any = {
      body: content_,
      observe: 'response',
      responseType: 'blob',
      headers: new HttpHeaders({
        Accept: 'application/json',
      }),
    };

    return this.http
      .request('post', url_, options_)
      .pipe(
        _observableMergeMap((response_: any) => {
          return this.processAddFile(response_);
        })
      )
      .pipe(
        _observableCatch((response_: any) => {
          if (response_ instanceof HttpResponseBase) {
            try {
              return this.processAddFile(<any>response_);
            } catch (e) {
              return <Observable<string>>(<any>_observableThrow(e));
            }
          } else return <Observable<string>>(<any>_observableThrow(response_));
        })
      );
  }

  protected processAddFile(response: HttpResponseBase): Observable<string> {
    const status = response.status;
    const responseBlob =
      response instanceof HttpResponse
        ? response.body
        : (<any>response).error instanceof Blob
        ? (<any>response).error
        : undefined;

    let _headers: any = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          let result200: any = null;
          let resultData200 = _responseText === '' ? null : JSON.parse(_responseText, this.jsonParseReviver);
          result200 = resultData200 !== undefined ? resultData200 : <any>null;
          return _observableOf(result200);
        })
      );
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(
        _observableMergeMap((_responseText) => {
          return throwException('An unexpected server error occurred.', status, _responseText, _headers);
        })
      );
    }
    return _observableOf<string>(<any>null);
  }
}

function throwException(
  message: string,
  status: number,
  response: string,
  headers: { [key: string]: any },
  result?: any
): Observable<any> {
  if (result !== null && result !== undefined) return _observableThrow(result);
  else return _observableThrow(null);
}

function blobToText(blob: any): Observable<string> {
  return new Observable<string>((observer: any) => {
    if (!blob) {
      observer.next('');
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = (event) => {
        observer.next((<any>event.target).result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
