/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { OutputGetAllRequestsDto } from '../models/output-get-all-requests-dto';
import { OutputGetRequestDetailsDto } from '../models/output-get-request-details-dto';

@Injectable({
  providedIn: 'root',
})
export class MerchantTrackingManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantTrackingManagementGetRequests
   */
  static readonly MerchantTrackingManagementGetRequestsPath = '/api/admin/MerchantTrackingManagement/GetRequests';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTrackingManagementGetRequests$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequests$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllRequestsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantTrackingManagementService.MerchantTrackingManagementGetRequestsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllRequestsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTrackingManagementGetRequests$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequests$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllRequestsDto>> {

    return this.merchantTrackingManagementGetRequests$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllRequestsDto>>) => r.body as Array<OutputGetAllRequestsDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTrackingManagementGetRequests()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequests$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllRequestsDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantTrackingManagementService.MerchantTrackingManagementGetRequestsPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllRequestsDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTrackingManagementGetRequests$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequests(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllRequestsDto>> {

    return this.merchantTrackingManagementGetRequests$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllRequestsDto>>) => r.body as Array<OutputGetAllRequestsDto>)
    );
  }

  /**
   * Path part for operation merchantTrackingManagementGetRequestDetails
   */
  static readonly MerchantTrackingManagementGetRequestDetailsPath = '/api/admin/MerchantTrackingManagement/GetRequestDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTrackingManagementGetRequestDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequestDetails$Plain$Response(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetRequestDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantTrackingManagementService.MerchantTrackingManagementGetRequestDetailsPath, 'get');
    if (params) {
      rb.query('RequestId', params.RequestId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetRequestDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTrackingManagementGetRequestDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequestDetails$Plain(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<OutputGetRequestDetailsDto> {

    return this.merchantTrackingManagementGetRequestDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetRequestDetailsDto>) => r.body as OutputGetRequestDetailsDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTrackingManagementGetRequestDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequestDetails$Response(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetRequestDetailsDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantTrackingManagementService.MerchantTrackingManagementGetRequestDetailsPath, 'get');
    if (params) {
      rb.query('RequestId', params.RequestId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetRequestDetailsDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTrackingManagementGetRequestDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementGetRequestDetails(params?: {
    RequestId?: number;
    context?: HttpContext
  }
): Observable<OutputGetRequestDetailsDto> {

    return this.merchantTrackingManagementGetRequestDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetRequestDetailsDto>) => r.body as OutputGetRequestDetailsDto)
    );
  }

  /**
   * Path part for operation merchantTrackingManagementUpdateRequestStatues
   */
  static readonly MerchantTrackingManagementUpdateRequestStatuesPath = '/api/admin/MerchantTrackingManagement/UpdateRequestStatues';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTrackingManagementUpdateRequestStatues$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementUpdateRequestStatues$Plain$Response(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantTrackingManagementService.MerchantTrackingManagementUpdateRequestStatuesPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
      rb.query('Statues', params.Statues, {"style":"form"});
      rb.query('note', params.note, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTrackingManagementUpdateRequestStatues$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementUpdateRequestStatues$Plain(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.merchantTrackingManagementUpdateRequestStatues$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantTrackingManagementUpdateRequestStatues()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementUpdateRequestStatues$Response(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantTrackingManagementService.MerchantTrackingManagementUpdateRequestStatuesPath, 'get');
    if (params) {
      rb.query('Id', params.Id, {"style":"form"});
      rb.query('Statues', params.Statues, {"style":"form"});
      rb.query('note', params.note, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantTrackingManagementUpdateRequestStatues$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantTrackingManagementUpdateRequestStatues(params?: {
    Id?: number;
    Statues?: number;
    note?: string;
    context?: HttpContext
  }
): Observable<boolean> {

    return this.merchantTrackingManagementUpdateRequestStatues$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

}
