import { Injectable } from '@angular/core';

import jwt_decode from 'jwt-decode';
import {
  OutputAllMenuPagesPermissionDto,
  MerchantManagementService,
  MerchantAccountService,
  InputRefreshTokenDto,
} from 'src/app/shared/api';
import { Router } from '@angular/router';

export const TOKEN_NAME = 'token';
export const REFRESH_TOKEN_NAME = 'refreshToken';
@Injectable()
export class AuthService {
  constructor(
    private accountService: MerchantAccountService,
    private merchantManagementService: MerchantManagementService,
    private router: Router
  ) {}

  currentPage = 0;
  isLogin(): boolean {
    if (this.getToken() !== null && !this.isTokenExpired(this.getToken())) {
      this.setMenuPagePermissionForMerchant();
      return true;
    } else {
      return false;
    }
  }
  getToken(): string {
    return localStorage.getItem(TOKEN_NAME);
  }
  setMenuPagePermission(): Promise<any> {
    return this.merchantManagementService
      .merchantManagementGetMenusPagesPermissionForMerchantByRoleId({
        roleId: +this.getRoleId(),
      })
      .toPromise()
      .then((value) => localStorage.setItem('Permission', JSON.stringify(value)))
      .catch((error) => {
        console.log(error);
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        this.router.navigate(['/Login']);
      });
  }
  setToken(token: string): void {
    localStorage.setItem(TOKEN_NAME, token);
  }
  getRefreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN_NAME);
  }

  setRefreshToken(refreshToken: string): void {
    localStorage.setItem(REFRESH_TOKEN_NAME, refreshToken);
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token) as any;

    if (decoded.exp === undefined) {
      return null;
    }

    const date = new Date(0);
    date.setUTCSeconds(decoded.exp);
    return date;
  }

  getRoleId() {
    const decoded = jwt_decode(this.getToken()) as any;

    if (decoded.RoleId === undefined) {
      return null;
    }

    return decoded.RoleId;
  }
  isTokenExpired(token?: string): boolean {
    if (!token) {
      token = this.getToken();
    }
    if (!token) {
      return true;
    }

    const date = this.getTokenExpirationDate(token);
    if (date === undefined) {
      return false;
    }
    return !(date.valueOf() > new Date().valueOf());
  }

  login(inputSignInUserDto): Promise<boolean> {
    return this.accountService
      .merchantAccountSignInMerchant({ body: inputSignInUserDto })
      .toPromise()
      .then(
        (value) => {
          if (value) {
            this.setToken(value.token);
            this.setMenuPagePermissionForMerchant();
            this.refreshToken(value.token, value.refreshToken);

            return true;
          } else {
            return false;
          }
        },
        (error) => {
          return false;
        }
      );
  }

  setMenuPagePermissionForMerchant(): Promise<any> {
    return this.merchantManagementService
      .merchantManagementGetMenusPagesPermissionForMerchantByRoleId({ roleId: +this.getRoleId() })
      .toPromise()
      .then((value) => {
        // if (value.length===0){
        //   localStorage.removeItem('token');
        //   localStorage.removeItem('refreshToken');
        //   this.router.navigate(['/Login']);
        // }

        localStorage.setItem('Permission', JSON.stringify(value));
      });
  }

  getMenuPagePermissionForMerchant(): OutputAllMenuPagesPermissionDto[] {
    return JSON.parse(localStorage.getItem('Permission').toString()) as OutputAllMenuPagesPermissionDto[];
  }

  refreshToken(token, refreshToken) {
    const millisTill10 = new Date(this.getTokenExpirationDate(token)).getTime() - new Date().getTime();

    const time = setTimeout(() => {
      this.accountService
        .merchantAccountToken({
          body: {
            token,
            refreshToken,
          } as InputRefreshTokenDto,
        })
        .subscribe(
          (value) => {
            this.setRefreshToken(value.refreshToken);
            this.setToken(value.token);
            this.setRefreshToken(value.refreshToken);
            this.refreshToken(value.token, value.refreshToken);
          },
          (error) => {
            clearTimeout(time);
          }
        );
    }, millisTill10);
  }

  refreshCurrentToken(): Promise<boolean> {
    if (this.getToken() == null) {
      return new Promise((value) => value(false));
    }
    return this.accountService
      .merchantAccountToken({
        body: {
          token: this.getToken(),
          refreshToken: this.getRefreshToken(),
        } as InputRefreshTokenDto,
      })
      .toPromise()
      .then(
        (value) => {
          this.setRefreshToken(value.refreshToken);
          this.setToken(value.token);
          this.refreshToken(value.token, value.refreshToken);
          return true;
        },
        (error) => {
          return false;
        }
      );
  }
}
