import {
  Component,
  Input,
  forwardRef,
  AfterViewInit,
  HostListener,
  OnChanges,
  ViewEncapsulation,
  ViewChild,
  ElementRef,
  Output,
  OnInit,
} from '@angular/core';
import {
  trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import {
  NG_VALUE_ACCESSOR,
  ControlValueAccessor,
  FormControl,
  DefaultValueAccessor,
  AbstractControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-droup-down',
  templateUrl: './droup-down.component.html',
  styleUrls: ['./droup-down.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: DroupDownComponent,
      multi: true,
    },
  ],
  animations: [
    trigger('visibilityChanged', [
      state('true', style({ height: '*', 'padding-top': '0px' })),
      state('false', style({ height: '0px', 'padding-top': '0px' })),
      transition('*=>*', animate('200ms')),
    ]),
  ],
})
export class DroupDownComponent implements ControlValueAccessor, AfterViewInit, OnChanges, OnInit {
  innerValue = '';
  isRender = false;
  @Input() data = [];
  filter = [];
  @Input() Textvalue = '';
  @Input() disabled = false;
  @Input() Idvalue = '';
  @Input() text = '';
  @Input() allowAddingNewItem = false;
  @Input() allowDeletingItem = false;
  @Input() hasImage = false;
  @Input() hasColorBox = false;
  @Input() colorBoxField = '';
  @Input() imageField = '';
  @Input() control: FormControl = new FormControl();
  @Output() valueChanged = new EventEmitter<any>();

  @Output() addingNewItem = new EventEmitter<any>();

  @Output() deletingItem = new EventEmitter<any>();
  errors: Array<any> = [];
  isRequired;
  @ViewChild('input') inputRef: ElementRef;
  selectedItem;
  results: string[];

  constructor(private translate: TranslateService) {}

  ngOnInit(): void {
    if (this.control.validator) {
      const validator = this.control.validator({} as AbstractControl);
      setTimeout(() => {
        if (validator && validator.required) {
          this.isRequired = true;

          this.checkForError();
        }
      }, 200);
    }
    this.innerValue = this.control.value;
    this.dataMapping();
  }

  dataMapping() {
    if (this.data) {
      this.filter = this.data.map((va) => {
        return {
          label: va[this.Textvalue],
          value: va[this.Idvalue],
          image: va[this.imageField],
          colorCode: va[this.colorBoxField],
        };
      });
      if (this.allowAddingNewItem) {
        this.filter.push({ label: null, value: '' });
      }
      this.selectedItem = this.filter.filter((em) => em.value == this.innerValue)[0];
      this.isRender = true;
    }
  }

  checkForError(): void {
    this.errors = [];
    for (const key in this.control.errors) {
      if (this.control.errors.hasOwnProperty(key)) {
        this.errors.push(this.translate.instant(key + 'v'));
      }
    }
  }
  addNewItem(): void {
    setTimeout(() => {
      this.value = '';
      this.selectedItem = this.filter.filter((em) => em.value == this.value)[0];
      this.innerValue = this.selectedItem.value;
      this.propagateChange(this.innerValue);
      this.valueChanged.emit(this.innerValue);
      this.addingNewItem.emit();
    }, 0);
  }
  deleteItem(id): void {
    setTimeout(() => {
      this.value = '';
      this.selectedItem = this.filter.filter((em) => em.value == this.value)[0];
      this.innerValue = this.selectedItem.value;
      this.propagateChange(this.innerValue);
      this.valueChanged.emit(this.innerValue);
      this.deletingItem.emit(id);
    }, 0);
  }
  search(event): void {
    this.onChange(null, { value: null });
    this.filter = this.data
      .filter((em) => (em[this.Textvalue] as string).toLowerCase().includes(event.query.toLowerCase()))
      .map((va) => {
        return {
          label: va[this.Textvalue],
          value: va[this.Idvalue],
          image: va[this.imageField],
          colorCode: va[this.colorBoxField],
        };
      });
    if (this.allowAddingNewItem) {
      this.filter.push({ label: null, value: '' });
    }
  }

  ngOnChanges(...args: any[]): void {
    try {
      this.dataMapping();

      this.value = args[0].control.currentValue.value;
      this.selectedItem = this.filter.filter((em) => em.value == this.value)[0];

      this.control.valueChanges.subscribe((value) => {
        this.innerValue = value;
        this.selectedItem = this.filter.filter((em) => em.value == this.value)[0];
        this.checkForError();
      });

      if (this.value !== '') {
        this.onChange(null, this.value);
      }
    } catch (e) {
      console.log(e);
    }
  }

  ngAfterViewInit(): void {
    this.control.valueChanges.subscribe((valueChanges) => {
      if (valueChanges?.value) {
        this.innerValue = valueChanges.value;
        this.selectedItem = this.filter.filter((em) => em.value == this.innerValue)[0];
        this.checkForError();
      }
    });
  }

  onChange(e: Event, selectedItem: any): void {
    if (selectedItem?.value) {
      this.innerValue = selectedItem.value;
      this.propagateChange(this.innerValue);
      this.valueChanged.emit(this.innerValue);
    }
    this.checkForError();
  }
  touch(): void {
    this.control.markAllAsTouched();
    this.checkForError();
  }
  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
    }
  }

  propagateChange = (_: any) => {};

  writeValue(value: any) {
    this.innerValue = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any) {
    this.checkForError();
  }
}
