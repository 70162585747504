import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FeatherIconsComponent } from './components/feather-icons/feather-icons.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { ToggleFullscreenDirective } from './directives/fullscreen.directive';
import { ContentLayoutComponent } from './layout/content-layout/content-layout.component';
import { NavService } from './service/nav.service';
import { WINDOW_PROVIDERS } from './service/windows.service';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { RightSidebarComponent } from './components/right-sidebar/right-sidebar.component';
import { TableService } from './service/table.service';
import { NgbdSortableHeader } from './directives/NgbdSortableHeader';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { InputComponent } from './components/input/input.component';
import { MatSelectModule } from '@angular/material/select';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { AuthService } from './AuthService';
import { AuthGuardService } from './AuthGuardService';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import { ToastModule } from 'primeng/toast';
import { ConfirmationService, MessageService } from 'primeng/api';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { DropdownModule } from 'primeng/dropdown';
import { DroupDownComponent } from './components/droup-down/droup-down.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { UploadFile } from './swagger/FileUpload';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ColorDropDownComponent } from './components/color-drop-down/color-drop-down.component';
import { SingleSelectMenuComponent } from './components/single-select-menu/single-select-menu.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule } from 'primeng/button';
import { MessagesModule } from 'primeng/messages';
import { PaginatorModule } from 'primeng/paginator';
import { MerchantAccountService } from './api';
import { MatStepperModule } from '@angular/material/stepper';
import { DialogModule } from 'primeng/dialog';
import { CalendarModule } from 'primeng/calendar';
import { ChipCustomComponent } from './components/chip-custom/chip-custom.component';
import { MatChipsModule } from '@angular/material/chips';
import { ChipsModule } from 'primeng/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, document.baseURI + 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    InputComponent,
    DroupDownComponent,
    ColorDropDownComponent,
    SingleSelectMenuComponent,
    ToggleFullscreenDirective,
    FeatherIconsComponent,
    FooterComponent,
    HeaderComponent,
    SidebarComponent,
    ContentLayoutComponent,
    BreadcrumbComponent,
    RightSidebarComponent,
    NgbdSortableHeader,
    ChipCustomComponent,
  ],
  imports: [
    CommonModule,
    MatSelectModule,
    ReactiveFormsModule,
    FormsModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      isolate: false,
    }),
    InputTextModule,
    TableModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    AutoCompleteModule,
    MatCheckboxModule,
    MatRadioModule,
    RouterModule,
    DragDropModule,
    CKEditorModule,
    ConfirmDialogModule,
    MessagesModule,
    NgbNavModule,
    MultiSelectModule,
    ChipsModule,
    MatChipsModule,
    PaginatorModule,
    MatAutocompleteModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    TranslateModule,
    InputComponent,
    InputTextModule,
    TableModule,
    ToastModule,
    ButtonModule,
    DropdownModule,
    DroupDownComponent,
    ColorDropDownComponent,
    SingleSelectMenuComponent,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    AutoCompleteModule,
    MultiSelectModule,
    MatRadioModule,
    FeatherIconsComponent,
    DragDropModule,
    CKEditorModule,
    ConfirmDialogModule,
    MessagesModule,
    NgbNavModule,
    PaginatorModule,
    MatStepperModule,
    DialogModule,
    CalendarModule,
    ChipCustomComponent,
    ChipsModule,
    MatChipsModule,
    MatAutocompleteModule,
  ],
  providers: [
    NavService,
    TableService,
    WINDOW_PROVIDERS,
    AuthGuardService,
    AuthService,
    MerchantAccountService,
    MessageService,

    UploadFile,
    ConfirmationService,
  ],
})
export class SharedModule {}
