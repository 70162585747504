/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpContext } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { InputCreateBundleDto } from '../models/input-create-bundle-dto';
import { InputCreateOfferDto } from '../models/input-create-offer-dto';
import { InputDeleteBundleDto } from '../models/input-delete-bundle-dto';
import { InputDeleteOfferDto } from '../models/input-delete-offer-dto';
import { InputUpdateBundleDto } from '../models/input-update-bundle-dto';
import { InputUpdateOfferDto } from '../models/input-update-offer-dto';
import { OutputGetAllOfferDto } from '../models/output-get-all-offer-dto';
import { OutputGetBundleDetailDto } from '../models/output-get-bundle-detail-dto';

@Injectable({
  providedIn: 'root',
})
export class MerchantOfferManagementService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation merchantOfferManagementCreateOfferForMerchant
   */
  static readonly MerchantOfferManagementCreateOfferForMerchantPath = '/api/Merchant/MerchantOfferManagement/CreateOfferForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementCreateOfferForMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateOfferForMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementCreateOfferForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementCreateOfferForMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateOfferForMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementCreateOfferForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementCreateOfferForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateOfferForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementCreateOfferForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementCreateOfferForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateOfferForMerchant(params?: {
    context?: HttpContext
    body?: InputCreateOfferDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementCreateOfferForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantOfferManagementUpdateOfferForMerchant
   */
  static readonly MerchantOfferManagementUpdateOfferForMerchantPath = '/api/Merchant/MerchantOfferManagement/UpdateOfferForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementUpdateOfferForMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateOfferForMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementUpdateOfferForMerchantPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementUpdateOfferForMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateOfferForMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementUpdateOfferForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementUpdateOfferForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateOfferForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementUpdateOfferForMerchantPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementUpdateOfferForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateOfferForMerchant(params?: {
    context?: HttpContext
    body?: InputUpdateOfferDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementUpdateOfferForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantOfferManagementGetAllOfferForMerchant
   */
  static readonly MerchantOfferManagementGetAllOfferForMerchantPath = '/api/Merchant/MerchantOfferManagement/GetAllOfferForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementGetAllOfferForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllOfferForMerchant$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllOfferDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementGetAllOfferForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllOfferDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementGetAllOfferForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllOfferForMerchant$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllOfferDto>> {

    return this.merchantOfferManagementGetAllOfferForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllOfferDto>>) => r.body as Array<OutputGetAllOfferDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementGetAllOfferForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllOfferForMerchant$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetAllOfferDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementGetAllOfferForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetAllOfferDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementGetAllOfferForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllOfferForMerchant(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetAllOfferDto>> {

    return this.merchantOfferManagementGetAllOfferForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetAllOfferDto>>) => r.body as Array<OutputGetAllOfferDto>)
    );
  }

  /**
   * Path part for operation merchantOfferManagementDeleteOfferForMerchant
   */
  static readonly MerchantOfferManagementDeleteOfferForMerchantPath = '/api/Merchant/MerchantOfferManagement/DeleteOfferForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementDeleteOfferForMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteOfferForMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementDeleteOfferForMerchantPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementDeleteOfferForMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteOfferForMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementDeleteOfferForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementDeleteOfferForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteOfferForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementDeleteOfferForMerchantPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementDeleteOfferForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteOfferForMerchant(params?: {
    context?: HttpContext
    body?: InputDeleteOfferDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementDeleteOfferForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantOfferManagementCreateBundleForMerchant
   */
  static readonly MerchantOfferManagementCreateBundleForMerchantPath = '/api/Merchant/MerchantOfferManagement/CreateBundleForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementCreateBundleForMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateBundleForMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementCreateBundleForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementCreateBundleForMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateBundleForMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementCreateBundleForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementCreateBundleForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateBundleForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementCreateBundleForMerchantPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementCreateBundleForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementCreateBundleForMerchant(params?: {
    context?: HttpContext
    body?: InputCreateBundleDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementCreateBundleForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantOfferManagementDeleteBundleForMerchant
   */
  static readonly MerchantOfferManagementDeleteBundleForMerchantPath = '/api/Merchant/MerchantOfferManagement/DeleteBundleForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementDeleteBundleForMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteBundleForMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementDeleteBundleForMerchantPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementDeleteBundleForMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteBundleForMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementDeleteBundleForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementDeleteBundleForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteBundleForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementDeleteBundleForMerchantPath, 'delete');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementDeleteBundleForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementDeleteBundleForMerchant(params?: {
    context?: HttpContext
    body?: InputDeleteBundleDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementDeleteBundleForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantOfferManagementUpdateBundleForMerchant
   */
  static readonly MerchantOfferManagementUpdateBundleForMerchantPath = '/api/Merchant/MerchantOfferManagement/UpdateBundleForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementUpdateBundleForMerchant$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateBundleForMerchant$Plain$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementUpdateBundleForMerchantPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementUpdateBundleForMerchant$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateBundleForMerchant$Plain(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementUpdateBundleForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementUpdateBundleForMerchant()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateBundleForMerchant$Response(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<StrictHttpResponse<boolean>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementUpdateBundleForMerchantPath, 'put');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementUpdateBundleForMerchant$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  merchantOfferManagementUpdateBundleForMerchant(params?: {
    context?: HttpContext
    body?: InputUpdateBundleDto
  }
): Observable<boolean> {

    return this.merchantOfferManagementUpdateBundleForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<boolean>) => r.body as boolean)
    );
  }

  /**
   * Path part for operation merchantOfferManagementGetAllBundleWithItemForMerchant
   */
  static readonly MerchantOfferManagementGetAllBundleWithItemForMerchantPath = '/api/Merchant/MerchantOfferManagement/GetAllBundleWithItemForMerchant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementGetAllBundleWithItemForMerchant$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllBundleWithItemForMerchant$Plain$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetBundleDetailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementGetAllBundleWithItemForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetBundleDetailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementGetAllBundleWithItemForMerchant$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllBundleWithItemForMerchant$Plain(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetBundleDetailDto>> {

    return this.merchantOfferManagementGetAllBundleWithItemForMerchant$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetBundleDetailDto>>) => r.body as Array<OutputGetBundleDetailDto>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementGetAllBundleWithItemForMerchant()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllBundleWithItemForMerchant$Response(params?: {
    context?: HttpContext
  }
): Observable<StrictHttpResponse<Array<OutputGetBundleDetailDto>>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementGetAllBundleWithItemForMerchantPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OutputGetBundleDetailDto>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementGetAllBundleWithItemForMerchant$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetAllBundleWithItemForMerchant(params?: {
    context?: HttpContext
  }
): Observable<Array<OutputGetBundleDetailDto>> {

    return this.merchantOfferManagementGetAllBundleWithItemForMerchant$Response(params).pipe(
      map((r: StrictHttpResponse<Array<OutputGetBundleDetailDto>>) => r.body as Array<OutputGetBundleDetailDto>)
    );
  }

  /**
   * Path part for operation merchantOfferManagementGetBundleDetails
   */
  static readonly MerchantOfferManagementGetBundleDetailsPath = '/api/Merchant/MerchantOfferManagement/GetBundleDetails';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementGetBundleDetails$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetBundleDetails$Plain$Response(params?: {
    BundleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBundleDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementGetBundleDetailsPath, 'get');
    if (params) {
      rb.query('BundleId', params.BundleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBundleDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementGetBundleDetails$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetBundleDetails$Plain(params?: {
    BundleId?: number;
    context?: HttpContext
  }
): Observable<OutputGetBundleDetailDto> {

    return this.merchantOfferManagementGetBundleDetails$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBundleDetailDto>) => r.body as OutputGetBundleDetailDto)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `merchantOfferManagementGetBundleDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetBundleDetails$Response(params?: {
    BundleId?: number;
    context?: HttpContext
  }
): Observable<StrictHttpResponse<OutputGetBundleDetailDto>> {

    const rb = new RequestBuilder(this.rootUrl, MerchantOfferManagementService.MerchantOfferManagementGetBundleDetailsPath, 'get');
    if (params) {
      rb.query('BundleId', params.BundleId, {"style":"form"});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json',
      context: params?.context
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OutputGetBundleDetailDto>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `merchantOfferManagementGetBundleDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  merchantOfferManagementGetBundleDetails(params?: {
    BundleId?: number;
    context?: HttpContext
  }
): Observable<OutputGetBundleDetailDto> {

    return this.merchantOfferManagementGetBundleDetails$Response(params).pipe(
      map((r: StrictHttpResponse<OutputGetBundleDetailDto>) => r.body as OutputGetBundleDetailDto)
    );
  }

}
