/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { DashboardService } from './services/dashboard.service';
import { MerchantAccountService } from './services/merchant-account.service';
import { MerchantCompanyService } from './services/merchant-company.service';
import { MerchantItemService } from './services/merchant-item.service';
import { MerchantManagementService } from './services/merchant-management.service';
import { MerchantOfferManagementService } from './services/merchant-offer-management.service';
import { MerchantProfileManagementService } from './services/merchant-profile-management.service';
import { MerchantTrackingManagementService } from './services/merchant-tracking-management.service';
import { OfferManagementService } from './services/offer-management.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    DashboardService,
    MerchantAccountService,
    MerchantCompanyService,
    MerchantItemService,
    MerchantManagementService,
    MerchantOfferManagementService,
    MerchantProfileManagementService,
    MerchantTrackingManagementService,
    OfferManagementService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
